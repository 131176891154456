import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { Row, Col, Grid } from 'antd';
import FloatingContainer from '../../components/floatingContainer'
import LoadingSpinner from '../../components/loading';
import StatusTag from '../../components/statusTag';
import InvoicePreviewV2 from '../../components/invoicePreviewV2';
import InvoicePaymentSchedules from '../../components/invoicePaymentSchedules';
import InvoicePayments from '../../components/invoicePayments';
import { get, isEmpty } from "lodash";
import { getInvoicePreviewV2 } from '../../api';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { formatDateMedium, formatEventDateMedium } from '../../helpers/dateHelper';
import InvoiceStatus from '../../constants/invoiceStatus';
import InvoicePaymentStatus from '../../constants/invoicePaymentStatus';
import { formatCurrencyString } from "../../helpers/contractHelper";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const InvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [eventInvoice, setEventInvoice] = useState({});
  const [paymentSchedulePayments, setPaymentSchedulePayments] = useState([]);
  const [payments, setPayments] = useState([]);
  const [nextPayment, setNextPaymnent] = useState({});
  const [pendingPayment, setPendingPayment] = useState({});
  const [showPaymentsSection, setShowPaymentsSection] = useState(false);
  const [lastPayment, setLastPayment] = useState({});

  useDocumentTitle("Invoice")
  const params = useParams();
  const navigate = useNavigate();

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    window.scrollTo(0, 0)
    if (params.id) {
      loadPage()
    } else {
      setInvoiceStatus("INVALID")
      setLoading(false)
    }
  }, [])

  const loadPage = async () => {
    try {
      const results = await getInvoicePreviewV2(params.id)
      const status = get(results, "data.status", null)
      if (status == "not-found" || status == "failed") {
        setInvoiceStatus("INVALID")
        setLoading(false)
        return
      } else if (results.data) {
        setEventInvoice(results.data)
      }

      const invoiceStatus = get(results, "data.status", InvoiceStatus.UNPAID)
      const paymentResults = get(results, "data.payments", [])
      const hasCustomPayment = get(results, "data.has_custom_payment", false)
      setPayments(paymentResults)
      setShowPaymentsSection(invoiceStatus == InvoiceStatus.PAID || hasCustomPayment)

      const lastPaymentResult = paymentResults.findLast(x => x.status == InvoicePaymentStatus.COMPLETE)
      if (lastPaymentResult) {
        setLastPayment(lastPaymentResult)
      } else {
        setLastPayment({})
      }

      const hasPendingManualPayment = get(results, "data.has_pending_manual_payment", false)
      const pendingManualPayment = get(results, "data.pending_manual_payment", {})
      if (hasPendingManualPayment) {
        setPendingPayment(pendingManualPayment)
      }

      const paymentScheduleResults = get(results, "data.payment_schedules", [])
      setPaymentSchedulePayments(paymentScheduleResults)

      const nextPaymentSchedule = paymentScheduleResults.find(x => x.status != "PAID")
      if (nextPaymentSchedule) {
        setNextPaymnent(nextPaymentSchedule)
      }
    } catch {
      setInvoiceStatus("INVALID")
    } finally {
      setLoading(false)
    }
  }

  const onPayClick = async () => {
    navigate(`/v/invoice/${params.id}/payment`)
  }

  const navigateToPDF = () => {
    window.open(`${BASE_URL}/pdfs/v2/invoice/${params.id}`, "_blank")
  }

  const renderGreeting = () => {
    const firstName = !isEmpty(eventInvoice.bill_to_user) ? eventInvoice.bill_to_user.first_name : eventInvoice.bill_to_first_name

    if (eventInvoice.has_pending_manual_payment) {
      return (
        <div className="text-center mt-20 mb-50">
          <div className="fs-20 fw-700 mb-5">Hi {firstName},</div>
          <div className="fs-16">Your pending payment of {formatCurrencyString(pendingPayment.amount, eventInvoice.currency)}</div>
          <div className="fs-16">was initiated on { formatDateMedium(pendingPayment.payment_date, eventInvoice.account_settings, true) }</div>
        </div>
      )
    }

    if (showPaymentsSection && eventInvoice.status != InvoiceStatus.PAID ) {
      return (
        <div className="text-center mt-20 mb-50">
          <div className="fs-20 fw-700 mb-5">Hi {firstName},</div>
          <div className="fs-16">Your final payment of {formatCurrencyString(eventInvoice.balance, eventInvoice.currency)}</div>
          <div className="fs-16">is due on { formatDateMedium(eventInvoice.due_date, eventInvoice.account_settings, true)}</div>
        </div>
      )
    }

    if (!isEmpty(nextPayment)) {
      return (
        <div className="text-center mt-20 mb-50">
          <div className="fs-20 fw-700 mb-5">Hi {firstName},</div>
          <div className="fs-16">Your next payment of {formatCurrencyString(nextPayment.amount, eventInvoice.currency)}</div>
          <div className="fs-16">is due on { formatDateMedium(nextPayment.due_date, eventInvoice.account_settings, true)}</div>
        </div>
      )
    }

    return (
      <div className="mt-20"></div>
    )
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    const status = eventInvoice.status == "SENT" ? "UNPAID" : eventInvoice.status

    if (invoiceStatus == "INVALID") {
      return (
        <FloatingContainer className="ph-15" verticalPadding={50} maxWidth={800}>
          <div className="bg-white border radius-8 mb-20 p-30 text-center">
            <div className="fw-700">This link is invalid.</div>
            <div className="mt-5 c-text-gray">Please check the link and try again.</div>
          </div>
        </FloatingContainer>
      )
    }

    var billToFullName = `${eventInvoice.bill_to_first_name} ${eventInvoice.bill_to_last_name}`
    if (!isEmpty(eventInvoice.bill_to_user)) {
      billToFullName = `${eventInvoice.bill_to_user.first_name} ${eventInvoice.bill_to_user.last_name}`
    }

    return (
      <div>
        <FloatingContainer className={`ph-20 mb-20`} verticalPadding={30} maxWidth={800}>
          { renderGreeting() }
          <div className={`shadow-card-square ${paddingClass} mb-20`}>
            <Row align="middle">
              <Col flex={1}>
                <div className="fw-600 fs-20">Invoice #{eventInvoice.invoice_number}<StatusTag status={status} size="large" className="ml-10"/></div>
              </Col>
              <Col flex={0}>
                <div className="display-flex cursor-pointer p-5" onClick={() => navigateToPDF()}>
                  <FiDownload style={{ fontSize: 20, color: '#536DFE' }}/>
                </div>
              </Col>
            </Row>
            <Row className="mv-30" gutter={[15,30]}>
              <Col xs={12} sm={8}>
                <div className="c-text-gray fs-12 fw-700 mb-5">FROM</div>
                <div className="">{eventInvoice.company_name}</div>
              </Col>
              <Col xs={12} sm={8}>
                <div className="c-text-gray fs-12 fw-700 mb-5">TO</div>
                <div className="">{billToFullName}</div>
              </Col>
              <Col xs={12} sm={8}>
                <div className="c-text-gray fs-12 fw-700 mb-5">EVENT DATE</div>
                <div className="">{formatEventDateMedium(eventInvoice.event_date, eventInvoice.account_settings)}</div>
              </Col>
              <Col xs={12} sm={8}>
                <div className="c-text-gray fs-12 fw-700 mb-5">ISSUE DATE</div>
                <div className="">{formatDateMedium(eventInvoice.create_datetime, eventInvoice.account_settings)}</div>
              </Col>
              <Col xs={12} sm={8}>
                <div className="c-text-gray fs-12 fw-700 mb-5">DUE DATE</div>
                <div className="">{formatDateMedium(eventInvoice.due_date, eventInvoice.account_settings, true)}</div>
              </Col>
            </Row>
            {/* <Row className="mv-30" gutter={[15,30]}>
              <Col xs={12} sm={8}>
                <div className="c-text-gray fs-12 fw-700 mb-5">ISSUE DATE</div>
                <div className="">{formatDateMedium(eventInvoice.create_datetime, eventInvoice.account_settings)}</div>
              </Col>
              <Col xs={12} sm={8}>
                <div className="c-text-gray fs-12 fw-700 mb-5">DUE DATE</div>
                <div className="">{formatDateMedium(eventInvoice.due_date, eventInvoice.account_settings, true)}</div>
              </Col>
            </Row> */}
            { eventInvoice.status == InvoiceStatus.PAID ? (
              <div className="bg-light-blue text-center p-15 fw-600">This invoice was paid in full on {formatDateMedium(lastPayment.payment_date, eventInvoice.account_settings, true)}</div>
            ) : (
              <button className="primary-button" style={{ borderRadius: 0}} onClick={() => onPayClick()}>Pay Now</button>
            )}
          </div>
          <InvoicePreviewV2 
            invoice={eventInvoice}
          />
          { eventInvoice.payment_schedule && !showPaymentsSection && (
            <InvoicePaymentSchedules 
              payments={paymentSchedulePayments}
              invoice={eventInvoice}
            />
          )}
          { showPaymentsSection && (
            <InvoicePayments 
              payments={payments}
              invoice={eventInvoice}
            />
          )}
        </FloatingContainer>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container" style={{ minHeight: '100vh' }}>
      { renderContent() }
    </div>
  );
}

export default InvoicePage;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import { Form, Row, Col, Modal, Tooltip, Alert } from 'antd';
import { 
  getAccountClientPortalSettings,
  updateAccountClientPortalSettings,
  getAdminUsers
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos, MdOutlineClose, MdInfoOutline } from "react-icons/md";
import {
  renderSearchSelectField
} from '../../../components/formFields'
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { isEmpty } from "lodash";

const ClientPortalSettingsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [clientPortalSettings, setClientPortalSettings] = useState({});
  const [employees, setEmployees] = useState([]);
  const [isUpdateStaffOptionsModalVisible, setUpdateStaffOptionsModalVisible] = useState(false);
  const [visibleStaffOption, setVisibleStaffOption] = useState("ALL");

  useDocumentTitle("Client Portal Settings")
  const navigate = useNavigate();
  const [updateStaffSettingsForm] = Form.useForm();

  const [sendRequest] = useApi()

  const visibleStaffOptions = [
    { text: "All Assigned Staff (with settings)", value: "ALL"},
    { text: "Specific Staff Member", value: "CUSTOM"}
  ]

  const allAssignedStaffDescription = `Based on this selection, the client will see all assigned staff members who have the “Visible to client” checkbox selected on the event.  You also have the ability to control whether or not contact information is displayed for each staff member by using the “Display contact information” checkbox. These options will be checked by default when assigning a staff member, however you have the ability to customize this information for each event.`
  const customStaffDescription = `Based on this selection, the client will see the staff member selected above even if they have not been assigned to the event.`

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const clientPortalSettingsResults = await sendRequest(getAccountClientPortalSettings())
      setClientPortalSettings(clientPortalSettingsResults)
      setVisibleStaffOption(clientPortalSettingsResults.visible_staff)

      const employeeResults = await sendRequest(getAdminUsers())
      const employeeData = employeeResults.map((employee) => {
        const fullName = `${employee.first_name} ${employee.last_name} ${employee.admin_user_role_id == "OWNER" ? "(Owner)" : ""}`
        return {
          value: employee.user_id,
          text: fullName
        }
      })
      setEmployees(employeeData)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const startUpdateStaffSettings = () => {
    const fields = {
      visible_staff: clientPortalSettings.visible_staff,
      custom_staff_id: clientPortalSettings.custom_staff_id
    }
    updateStaffSettingsForm.setFieldsValue(fields)
    setUpdateStaffOptionsModalVisible(true)
  }

  const saveStaffSettings = async (values) => {
    try {
      await sendRequest(updateAccountClientPortalSettings(values))
      await refreshPage()
      setUpdateStaffOptionsModalVisible(false)
    } catch (error) {
      setUpdateStaffOptionsModalVisible(false)
    }
  }

  const onVisibleStaffOptionChange = (value) => {
    setVisibleStaffOption(value)
  }

  const getVisibleStaffName = () => {
    const option = visibleStaffOptions.find(x => x.value == clientPortalSettings.visible_staff)
    if (!isEmpty(option)) {
      if (clientPortalSettings.visible_staff == "CUSTOM" && !isEmpty(clientPortalSettings.custom_staff)) {
        return `${clientPortalSettings.custom_staff.first_name} ${clientPortalSettings.custom_staff.last_name}`
      }
      return option.text
    }
    return "--"
  }

  const getStaffVisibilityDescription = (option) => {
    if (option == "CUSTOM") {
      return customStaffDescription
    } else {
      return allAssignedStaffDescription
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Client Portal
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Client Portal Settings</div>
      </div>
    )
  }

  const renderUpdateDisplayOptionsModal = () => {
    return (
      <Modal visible={isUpdateStaffOptionsModalVisible} footer={null} onCancel={() => setUpdateStaffOptionsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Staff Settings</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateStaffOptionsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={updateStaffSettingsForm} layout="vertical" name="verify" onFinish={saveStaffSettings}>
            <div className="mt-10 mb-15">
              {renderSearchSelectField("Who is visible to the client?", "visible_staff", "Select an option...", visibleStaffOptions, true, onVisibleStaffOptionChange)}
              { visibleStaffOption == "CUSTOM" && (
              <>
                <div className="mt-15">
                  {renderSearchSelectField("Staff Member", "custom_staff_id", "Select an option...", employees, true)}
                </div>
              </>
            )}
              { visibleStaffOption != "" && (
                <div className="bg-gray p-10 mt-10 fs-12">{getStaffVisibilityDescription(visibleStaffOption)}</div>
              )}
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateStaffOptionsModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderLabelWithTooltip = (label, tooltip) => {
    return (
      <div className="display-flex flex-middle">
        <div className="fs-14 fw-700 display-flex">{label}</div>
        <Tooltip title={tooltip}>
          <MdInfoOutline style={{ fontSize: 14, marginLeft: 6, color: '#777'}}/>
        </Tooltip>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <Alert showIcon message={`The client portal settings below apply to both the website and the mobile app.`} type="info" style={{ marginBottom: 15 }}/>
          <div className="shadow-card p-20">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Staff</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => startUpdateStaffSettings()}>Edit</div>
              </Col>
            </Row>
            { renderLabelWithTooltip("Who is visible to the client?", "Determines which staff members the client can see within the website and mobile app.")}
            <div className="fs-14 mt-5">{getVisibleStaffName()}</div>
            <div className="bg-gray p-10 mt-10 fs-12">{getStaffVisibilityDescription(clientPortalSettings.visible_staff)}</div>
          </div>
        </FloatingContainer>
        { renderUpdateDisplayOptionsModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default ClientPortalSettingsPage;
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Grid, Row, Col, Input, DatePicker, Select, Modal, Switch, Button, Form, notification, Checkbox} from 'antd';
import NumberFormat from 'react-number-format';
import uniqid from 'uniqid';
import qs from "qs";
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useCurrency from '../../../hooks/useCurrency';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getInvoice, updateInvoice, getEventClients } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import StatusTag from '../../../components/statusTag'
import FloatingContainer from '../../../components/floatingContainer'
import { HiOutlineArrowLeft } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { UserOutlined } from '@ant-design/icons';
import { getNumericCurrency, getCurrencySymbol, formatCurrencyString } from '../../../helpers/contractHelper';
import { getDatePickerFormatMedium } from '../../../helpers/dateHelper';
import InvoiceStatus from '../../../constants/invoiceStatus';
import { isEmpty, cloneDeep, isArray } from "lodash";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import { 
  renderInputField,
  renderEmailField,
  renderSearchSelectField,
} from '../../../components/formFields'

const EventInvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [eventId, setEventId] = useState("");
  const [notes, setNotes] = useState("");
  const [dueDate, setDueDate] = useState(moment());
  const [dueDateOption, setDueDateOption] = useState("CUSTOM");
  const [eventInvoice, setEventInvoice] = useState({});
  const [clients, setClients] = useState([]);
  const [tempSelectedBillTo, setTempSelectedBillTo] = useState("");
  const [selectedBillTo, setSelectedBillTo] = useState("");
  const [selectedBillToFirstName, setSelectedBillToFirstName] = useState("");
  const [selectedBillToLastName, setSelectedBillToLastName] = useState("");
  const [selectedBillToEmail, setSelectedBillToEmail] = useState("");
  const [isPayNowErrorVisible, setPayNowErrorVisible] = useState(false);
  const [isPaymentScheduleActive, setPaymentScheduleActive] = useState(true);
  const [isPaymentRemindersActive, setPaymentRemindersActive] = useState(false);
  const [isTipsActive, setTipsActive] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [paymentSchedulePayments, setPaymentSchedulePayments] = useState([]);
  const [finalPaymentSchedulePayment, setFinalPaymentSchedulePayment] = useState({});
  const [availableLineItems, setAvailableLineItems] = useState([]);
  const [isAddItemModalVisible, setAddItemModalVisible] = useState(false);
  const [isBillToModalVisible, setBillToModalVisible] = useState(false);
  const [isDateValidModalVisible, setDateValidModalVisible] = useState(false);
  const [isAmountsValidModalVisible, setAmountsValidModalVisible] = useState(false);
  const [isZeroAmountModalVisible, setZeroAmountModalVisible] = useState(false);
  const [is7DaysBeforeReminderActive, set7DaysBeforeReminderActive] = useState(true);
  const [is3DaysBeforeReminderActive, set3DaysBeforeReminderActive] = useState(true);
  const [isDueDateReminderActive, setDueDateReminderActive] = useState(true);
  const [is3DaysAfterReminderActive, set3DaysAfterReminderActive] = useState(true);
  const [is7DaysAfterReminderActive, set7DaysAfterReminderActive] = useState(true);
  const [is14DaysAfterReminderActive, set14DaysAfterReminderActive] = useState(true);
  const [fromPage, setFromPage] = useState("");
  const [hasPayments, setHasPayments] = useState(false);

  useDocumentTitle("Edit Invoice")
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const [currency] = useCurrency()
  const [billToForm] = Form.useForm();

  const invoiceId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.from) {
      setFromPage(queryStrings.from)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getInvoice(invoiceId))
      if (results.status == InvoiceStatus.PAID) {
        navigate(`/admin/events/${results.event_id}?tab=documents`)
        return
      }
      setEventInvoice(results)
      setEventId(results.event_id)
      setSelectedBillTo(results.bill_to_user_id ? results.bill_to_user_id : 1)
      if (!isEmpty(results.bill_to_user)) {
        setSelectedBillToFirstName(results.bill_to_user.first_name)
        setSelectedBillToLastName(results.bill_to_user.last_name)
        setSelectedBillToEmail(results.bill_to_user.email)
      }
      setDueDate(moment.utc(results.due_date))
      setDueDateOption(results.due_date_helper)
      setLineItems(results.line_items ?? [])
      setAvailableLineItems(results.available_line_items ?? [])
      setNotes(results.notes)

      const paymentSchedules = results.payment_schedules ?? []
      const finalPayments = paymentSchedules.filter(x => x.final_payment == true)
      const otherPayments = paymentSchedules.filter(x => x.final_payment == false)
      setFinalPaymentSchedulePayment(finalPayments.length > 0 ? finalPayments[0] : {})
      setPaymentSchedulePayments(otherPayments)
      setPaymentScheduleActive(results.payment_schedule == true)
      setTipsActive(results.tips ?? false)
      const reminders = isArray(results.payment_reminders) ? results.payment_reminders : []
      if (reminders.length > 0) {
        setPaymentRemindersActive(true)
        set7DaysBeforeReminderActive(reminders.includes(-7))
        set3DaysBeforeReminderActive(reminders.includes(-3))
        setDueDateReminderActive(reminders.includes(0))
        set3DaysAfterReminderActive(reminders.includes(3))
        set7DaysAfterReminderActive(reminders.includes(7))
        set14DaysAfterReminderActive(reminders.includes(14))
      }

      const hasPaymentsResults = isArray(results.payments) && results.payments.length > 0;
      setHasPayments(hasPaymentsResults)

      const clientResults = await sendRequest(getEventClients(results.event_id))
      setClients(clientResults)
    } finally {
      setLoading(false)
    }
  }

  const onNext = async () => {

    if (hasPayments) {
      navigateNext()
      return
    }

    // Check if due dates are valid
    var isDatesValid = true
    for (const [index, payment] of paymentSchedulePayments.entries()) {
      const isBeforeFinal = isBeforeFinalDueDate(payment.due_date)
      const isBeforeNext = isBeforeNextPaymentDate(payment.due_date, index)
      if (!isBeforeFinal || !isBeforeNext) {
        isDatesValid = false
      }
    }

    if (!isDatesValid) {
      setDateValidModalVisible(true)
      return
    }

    // Check if amounts are valid
    const totalPayments = paymentSchedulePayments.reduce((x,y) => {
      return x + Number(y.amount);
    }, 0)
    const totalAmountDue = getAmountDue(lineItems)
    if (totalPayments > totalAmountDue) {
      setAmountsValidModalVisible(true)
      return
    }

    // Check if any amounts are missing
    var isAnyAmountZero = false
    for (const payment of paymentSchedulePayments) {
      if (payment.amount == 0) {
        isAnyAmountZero = true
      }
    }
    const isFinalAmountZero = finalPaymentSchedulePayment && finalPaymentSchedulePayment.amount == 0
    if (isAnyAmountZero || isFinalAmountZero) {
      setZeroAmountModalVisible(true)
      return
    }

    var newPaymentSchedulePayments = cloneDeep(paymentSchedulePayments)
    newPaymentSchedulePayments.push(finalPaymentSchedulePayment)

    var paymentReminders = []
    if (isPaymentRemindersActive) {
      if (is7DaysBeforeReminderActive) {
        paymentReminders.push(-7)
      }
      if (is3DaysBeforeReminderActive) {
        paymentReminders.push(-3)
      }
      if (isDueDateReminderActive) {
        paymentReminders.push(0)
      }
      if (is3DaysAfterReminderActive) {
        paymentReminders.push(3)
      }
      if (is7DaysAfterReminderActive) {
        paymentReminders.push(7)
      }
      if (is14DaysAfterReminderActive) {
        paymentReminders.push(14)
      }
    }

    try {
      const isBillToCustom = selectedBillTo == 1
      const body = {
        status: InvoiceStatus.DRAFT,
        bill_to_user_id: isBillToCustom ? null : selectedBillTo,
        bill_to_first_name: isBillToCustom ? selectedBillToFirstName : null,
        bill_to_last_name: isBillToCustom ? selectedBillToLastName : null,
        bill_to_email: isBillToCustom ? selectedBillToEmail : null,
        due_date: dueDate.startOf("day").format(),
        due_date_helper: dueDateOption,
        line_items: lineItems,
        total: getNumericCurrency(getAmountDue(lineItems)),
        notes: notes,
        payment_schedule: isPaymentScheduleActive,
        payment_schedules: newPaymentSchedulePayments,
        tips: isTipsActive,
        payment_reminders: paymentReminders
      }
      await sendRequest(updateInvoice(invoiceId, body))
      navigateNext()
    } catch {
      notification.error({
        message: 'Error',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const navigateNext = () => {
    if (fromPage == "invoices") {
      navigate(`/admin/invoices/${invoiceId}/preview?from=invoices-edit`)
    } else if (fromPage == "invoice-details") {
      navigate(`/admin/invoices/${invoiceId}/preview?from=invoice-details-edit`)
    } else {
      navigate(`/admin/invoices/${invoiceId}/preview?from=edit`)
    }
  }

  const getDateFromDateOption = (option) => {
    var currentDate = moment.utc().startOf('day')
    var eventDate = currentDate
    if (eventInvoice.event_date) {
      eventDate = moment.utc(eventInvoice.event_date, "YYYY-MM-DD")
    }

    var finalDate = currentDate
    if (option == "TODAY") {
      finalDate = finalDate
    } else if (option == "WITHIN_7_DAYS") {
      finalDate = finalDate.add(7, 'days')
    } else if (option == "WITHIN_14_DAYS") {
      finalDate = finalDate.add(14, 'days')
    } else if (option == "WITHIN_21_DAYS") {
      finalDate = finalDate.add(21, 'days')
    } else if (option == "WITHIN_30_DAYS") {
      finalDate = finalDate.add(30, 'days')
    } else if (option == "WITHIN_60_DAYS") {
      finalDate = finalDate.add(60, 'days')
    } else if (option == "WITHIN_90_DAYS") {
      finalDate = finalDate.add(90, 'days')
    } else if (option == "ON_EVENT_DATE") {
      finalDate = eventDate
    } else if (option == "7_DAYS_BEFORE_EVENT") {
      finalDate = eventDate.add(-7, 'days')
    } else if (option == "14_DAYS_BEFORE_EVENT") {
      finalDate = eventDate.add(-14, 'days')
    } else if (option == "21_DAYS_BEFORE_EVENT") {
      finalDate = eventDate.add(-21, 'days')
    } else if (option == "30_DAYS_BEFORE_EVENT") {
      finalDate = eventDate.add(-30, 'days')
    } else if (option == "60_DAYS_BEFORE_EVENT") {
      finalDate = eventDate.add(-60, 'days')
    } else if (option == "90_DAYS_BEFORE_EVENT") {
      finalDate = eventDate.add(-90, 'days')
    }

    if (finalDate.isBefore(currentDate)) {
      finalDate = currentDate
    }

    return finalDate
  }

  const onFinalDueDateOptionChange = (value) => {
    setDueDateOption(value)
    setDueDate(getDateFromDateOption(value))
    updateFinalPaymentDueDate(getDateFromDateOption(value).format())
  }

  const onDueDateOptionChange = (value, id) => {
    var newPaymentSchedulePayments = cloneDeep(paymentSchedulePayments)
    for (var record of newPaymentSchedulePayments) {
      if (record.payment_schedule_id == id) {
        record.due_date_helper = value
        record.due_date = getDateFromDateOption(value).format()
      }
    }
    setPaymentSchedulePayments(newPaymentSchedulePayments)
  }

  const onDueDateChange = (value) => {
    setDueDate(value)
    setDueDateOption("CUSTOM")
    updateFinalPaymentDueDate(value.format())
  }

  const onPaymentScheduleAmountChange = (index, value) => {
    var newPayments = cloneDeep(paymentSchedulePayments);
    newPayments[index].amount = getNumericCurrency(value);
    setPaymentSchedulePayments(newPayments)
    calculateFinalPaymentAmount(newPayments, lineItems)
  }

  const calculateFinalPaymentAmount = (payments, items) => {
    var newFinalPayment = cloneDeep(finalPaymentSchedulePayment);
    const totalPayments = payments.reduce((x,y) => {
      return x + Number(y.amount);
    }, 0)
    const lastPaymentAmount = getAmountDue(items) - totalPayments
    newFinalPayment.amount = lastPaymentAmount
    setFinalPaymentSchedulePayment(newFinalPayment)
  }

  const updateFinalPaymentDueDate = (date) => {
    var newFinalPayment = cloneDeep(finalPaymentSchedulePayment);
    newFinalPayment.due_date = date
    setFinalPaymentSchedulePayment(newFinalPayment)
  }

  const onAddPaymentSchedule = () => {
    var newPayments = cloneDeep(paymentSchedulePayments);
    const payment = {
      payment_schedule_id: uniqid(),
      amount: 0,
      due_date: moment.utc().startOf("day").format(),
      due_date_helper: "CUSTOM",
      final_payment: false,
      invoice_id: invoiceId,
      status: "UNPAID"
    }
    newPayments.push(payment)
    setPaymentSchedulePayments(newPayments)
  }

  const getPaymentScheduleAmountPercentage = (amount) => {
    const lineItemAmount = Number(amount)
    const amountDue = getAmountDue(lineItems)
    const percentage = amountDue > 0 ? (lineItemAmount / amountDue) * 100 : 0
    return percentage.toFixed(2)
  }

  const getDiscountText = (item) => {
    if (item.discount_type == "PERCENTAGE") {
      return `Discount applied (${item.discount_percentage})`
    } else {
      return "Discount applied"
    }
  }

  const getAmountDue = (items) => {
    return items.reduce((x,y) => {
      return x + Number(y.grand_total);
    }, 0)
  }

  const getSubTotal = (items) => {
    return items.reduce((x,y) => {
      return x + Number(y.total_subtotal);
    }, 0)
  }

  const getTotalTaxes = (items) => {
    return items.reduce((x,y) => {
      return x + Number(y.total_tax);
    }, 0)
  }

  const addLineItem = (item) => {
    var newLineItems = cloneDeep(lineItems)
    newLineItems.push(item)
    setLineItems(newLineItems)
    calculateFinalPaymentAmount(paymentSchedulePayments, newLineItems)
  }

  const removeLineItem = (id) => {
    var newLineItems = cloneDeep(lineItems)
    newLineItems = newLineItems.filter(x => x.id != id)
    setLineItems(newLineItems)
    calculateFinalPaymentAmount(paymentSchedulePayments, newLineItems)
  }

  const onEditBillToUser = () => {
    setTempSelectedBillTo(selectedBillTo)
    billToForm.resetFields()
    billToForm.setFieldsValue({ bill_to: selectedBillTo })
    if (selectedBillTo == 1) {
      billToForm.setFieldsValue({ first_name: selectedBillToFirstName, last_name: selectedBillToLastName, email: selectedBillToEmail })
    }
    setBillToModalVisible(true)
  }

  const saveBillToUser = (values) => {
    setSelectedBillTo(values["bill_to"])
    if (values["bill_to"] == 1) {
      setSelectedBillToFirstName(values["first_name"])
      setSelectedBillToLastName(values["last_name"])
      setSelectedBillToEmail(values["email"])
    } else {
      const clientResult = clients.find(x => x.user_id == values["bill_to"])
      if (!isEmpty(clientResult)) {
        setSelectedBillToFirstName(clientResult.first_name)
        setSelectedBillToLastName(clientResult.last_name)
        setSelectedBillToEmail(clientResult.email)
      }
    }
    setBillToModalVisible(false)
  }

  const disabledDueDate = (current) => {
    // Can not select days before today
    return current && current < moment().add(-1, "day");
  }

  const setPaymentScheduleDueDate = (date, id) => {
    var newPaymentSchedulePayments = cloneDeep(paymentSchedulePayments)
    for (var record of newPaymentSchedulePayments) {
      if (record.payment_schedule_id == id) {
        record.due_date = date.format()
        record.due_date_helper = "CUSTOM"
      }
    }
    setPaymentSchedulePayments(newPaymentSchedulePayments)
  }

  const onRemovePaymentSchedule = (index) => {
    var newPaymentSchedulePayments = cloneDeep(paymentSchedulePayments)
    newPaymentSchedulePayments.splice(index, 1);
    setPaymentSchedulePayments(newPaymentSchedulePayments)
    calculateFinalPaymentAmount(newPaymentSchedulePayments, lineItems)
  }

  const onPaymentScheduleToggle = (value) => {
    setPaymentScheduleActive(value)
    if (value) {
      onAddPaymentSchedule()
    } else {
      setPaymentSchedulePayments([])
      calculateFinalPaymentAmount([], lineItems)
    }
  }

  const onPaymentReminderToggle = (value) => {
    setPaymentRemindersActive(value)
  }

  const isBeforeFinalDueDate = (date) => {
    return moment.utc(date).startOf("day").diff(dueDate, "days") < 0
  }

  const isBeforeNextPaymentDate = (date, index) => {
    var isBefore = true
    if (paymentSchedulePayments.length > 1 && index != paymentSchedulePayments.length - 1) {
      const nextPaymentDate = paymentSchedulePayments[index + 1].due_date
      isBefore = moment.utc(date).startOf("day").diff(moment.utc(nextPaymentDate), "days") < 0
    }
    return isBefore
  }

  const renderHeaderButton = () => {
    const buttonText = hasPayments ? "Next: Preview" : "Next: Save & Preview"
    return <button className="small-primary-button" style={{ }} onClick={() => onNext()}>{buttonText}</button>;
  }

  const renderHeader = () => {
    var backPath = `/admin/events/${eventId}?tab=documents`
    if (fromPage == "invoices") {
      backPath = `/admin/invoices`
    } else if (fromPage == "invoice-details") {
      backPath = `/admin/invoices/${invoiceId}`
    }

    if (screens.md) {
      return (
        <div className="event-contract--header p-20"> 
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <StatusTag status={eventInvoice.status}/>
            <div className="flex-1"></div>
            <div className="">
              { renderHeaderButton()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <StatusTag status={eventInvoice.status}/>
            <div className="flex-1"></div>
          </div>
          <div className="text-center mt-15">
            <div className="">
              { renderHeaderButton()}
            </div>
          </div>
        </div>
      )
    }
  }

  const renderPayNowErrorModal = () => {
    return (
      <Modal visible={isPayNowErrorVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setPayNowErrorVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Payment Link Needed</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">In order to include a <b>Pay Invoice</b> button, you will need to configure a Payment Link by going to the <b>Invoices</b> page under Account Setup.</div>
          <button className="primary-button" type="button" onClick={() => setPayNowErrorVisible(false)}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderBillToUser = () => {
    var clientPhoto = null;
    return (
      <Row gutter={[15,15]} align="middle" className="">
        <Col flex={0}>
          {/* { clientPhoto ? (
            <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${clientPhoto})`}} onClick={() => displayPhoto(clientPhoto)}></div>
          ) : ( */}
            <div className="display-flex">
              <div className="user-profile-circle small"><UserOutlined/></div>
            </div>
          {/* )} */}
        </Col>
        <Col flex={1}>
          <div className="fs-14 fw-700">{selectedBillToFirstName} {selectedBillToLastName}</div>
          <div className="fs-14 fw-500 c-text-gray line-1-1">{selectedBillToEmail}</div>
        </Col>
        <Col flex={0}>
          <div className="blue-link" onClick={() => onEditBillToUser()}>Edit</div>
        </Col>
      </Row>
    )
  }

  const renderDateOptions = () => {
    return (
      <>
        <Select.Option value="CUSTOM">Custom</Select.Option>
        <Select.Option value="TODAY">Today</Select.Option>
        <Select.Option value="WITHIN_7_DAYS">Within 7 days</Select.Option>  
        <Select.Option value="WITHIN_14_DAYS">Within 14 days</Select.Option>  
        <Select.Option value="WITHIN_21_DAYS">Within 21 days</Select.Option> 
        <Select.Option value="WITHIN_30_DAYS">Within 30 days</Select.Option>  
        <Select.Option value="WITHIN_60_DAYS">Within 60 days</Select.Option>  
        <Select.Option value="WITHIN_90_DAYS">Within 90 days</Select.Option>
        <Select.Option value="ON_EVENT_DATE">On event date</Select.Option>
        <Select.Option value="7_DAYS_BEFORE_EVENT">7 days before event</Select.Option>  
        <Select.Option value="14_DAYS_BEFORE_EVENT">14 days before event</Select.Option>  
        <Select.Option value="21_DAYS_BEFORE_EVENT">21 days before event</Select.Option>  
        <Select.Option value="30_DAYS_BEFORE_EVENT">30 days before event</Select.Option>  
        <Select.Option value="60_DAYS_BEFORE_EVENT">60 days before event</Select.Option>  
        <Select.Option value="90_DAYS_BEFORE_EVENT">90 days before event</Select.Option>  
      </>
    )
  }

  const renderPaymentScheduleDateError = (date, index) => {
    const isBeforeFinal = isBeforeFinalDueDate(date)
    const isBeforeNext = isBeforeNextPaymentDate(date, index)

    if (!isBeforeFinal) {
      return (
        <div className="fs-12 c-red mt-5">The date must be before the final due date</div>
      )
    } else if (!isBeforeNext) {
      return (
        <div className="fs-12 c-red mt-5">The date must be before the next payment date</div>
      )
    }
    return null
  }

  const renderPaymentScheduleRow = (item, index) => {
    const percentage = getPaymentScheduleAmountPercentage(item.amount)
    const isPercentageValid = Number(percentage) <= 100 && Number(percentage) > 0
    const percentageClass = isPercentageValid ? "c-text-gray" : "c-red"
    return (
      <Row className="bg-gray p-10 mt-10" gutter={[15,15]} key={index}>
        <Col xs={20} md={2} className="pt-15">
          {index + 1} of {paymentSchedulePayments.length + 1}
        </Col>
        { index > 0 && !screens.md && (
          <Col xs={4} style={{ alignSelf: 'center'}}>
            <div className="display-flex" style={{ justifyContent: 'flex-end'}} onClick={() => onRemovePaymentSchedule(index)}>
              <MdOutlineClose size={20} color={"#CCC"}/>
            </div>
          </Col>
        )}
        <Col xs={24} md={7}>
          <div style={{ position: "relative", height: 50 }}>
            <div style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
              <NumberFormat 
                className="ant-input ant-input-lg" 
                displayType={'input'} 
                thousandSeparator={true} 
                prefix={getCurrencySymbol(eventInvoice.currency)}
                decimalScale={2} 
                fixedDecimalScale={true}
                placeholder={`${getCurrencySymbol(eventInvoice.currency)}0.00`}
                value={item.amount}
                onChange={(e) => onPaymentScheduleAmountChange(index, e.target.value)}
              />
            </div>
            <div style={{ position: "absolute", top: 14, right: 10 }} className={`${percentageClass} fs-14`}>{percentage}%</div>
          </div>
        </Col>
        <Col xs={24} md={13}>
          { screens.md ? (
            <div className="border bg-white">
              <Row align="middle">
                <Col flex={0}>
                  <DatePicker size="large" format={getDatePickerFormatMedium(accountSettings)} value={moment.utc(item.due_date)} onChange={(date) => setPaymentScheduleDueDate(date, item.payment_schedule_id)} allowClear={false} bordered={false} disabledDate={disabledDueDate}/>
                </Col>
                <Col flex={1}>
                  <Select size="small" value={item.due_date_helper} style={{ textAlign: "right", paddingRight: 5, color: "#777777", width: "100%" }} onChange={(value) => onDueDateOptionChange(value, item.payment_schedule_id)} bordered={false}>
                    { renderDateOptions() }
                  </Select>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="border bg-white">
              <Row align="middle">
                <Col xs={24}>
                  <Select size="large" value={item.due_date_helper} style={{ color: "#777777", width: "100%", fontSize: 14 }} className="b-border" onChange={(value) => onDueDateOptionChange(value, item.payment_schedule_id)} bordered={false}>
                    { renderDateOptions() }
                  </Select>
                </Col>
                <Col xs={24}>
                  <DatePicker size="large" style={{ width: "100%" }} format={getDatePickerFormatMedium(accountSettings)} value={moment.utc(item.due_date)} onChange={(date) => setPaymentScheduleDueDate(date, item.payment_schedule_id)} allowClear={false} bordered={false} disabledDate={disabledDueDate}/>
                </Col>
              </Row>
            </div>
          )}
          { renderPaymentScheduleDateError(item.due_date, index) }
        </Col>
        { index > 0 && screens.md && (
          <Col xs={2} style={{ alignSelf: 'center'}}>
            <div className="display-flex" style={{ justifyContent: 'flex-end'}} onClick={() => onRemovePaymentSchedule(index)}>
              <MdOutlineClose size={20} color={"#CCC"}/>
            </div>
          </Col>
        )}
      </Row>
    )
  }

  const renderFinalPaymentScheduleRow = () => {
    const totalPaymentSchedules = paymentSchedulePayments.length + 1
    const percentage = getPaymentScheduleAmountPercentage(finalPaymentSchedulePayment.amount)
    const isPercentageValid = Number(percentage) <= 100 && Number(percentage) >= 0
    const percentageClass = isPercentageValid ? "c-text-gray" : "c-red"
    return (
      <Row className="bg-gray p-10 mt-10" gutter={[20,20]} align="middle">
        <Col xs={24} md={2}>
          {totalPaymentSchedules} of {totalPaymentSchedules}
        </Col>
        <Col xs={24} md={7}>
          <Row align="middle">
            <Col flex={1} className="pl-10 fs-16">
              { formatCurrencyString(finalPaymentSchedulePayment.amount, currency.code) }
            </Col>
            <Col flex={0} className="pr-10">
              <div className={`${percentageClass} fs-14`}>{percentage}%</div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={13} className="fs-16">
          { screens.md ? (
            <div className="border bg-white">
              <Row align="middle">
                <Col flex={0}>
                  <DatePicker size="large" format={getDatePickerFormatMedium(accountSettings)} value={dueDate} onChange={(date) => onDueDateChange(date)} allowClear={false} bordered={false} disabledDate={disabledDueDate}/>
                </Col>
                <Col flex={1}>
                  <Select size="small" value={dueDateOption} style={{ textAlign: "right", paddingRight: 5, color: "#777777", width: "100%" }} onChange={(value) => onFinalDueDateOptionChange(value)} bordered={false}>
                    { renderDateOptions() }
                  </Select>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="border bg-white">
              <Row align="middle">
                <Col xs={24}>
                  <Select size="large" value={dueDateOption} style={{ color: "#777777", width: "100%", fontSize: 14 }} className="b-border" onChange={(value) => onFinalDueDateOptionChange(value)} bordered={false}>
                    { renderDateOptions() }
                  </Select>
                </Col>
                <Col xs={24}>
                  <DatePicker size="large" style={{ width: "100%" }} format={getDatePickerFormatMedium(accountSettings)} value={dueDate} onChange={(date) => onDueDateChange(date)} allowClear={false} bordered={false} disabledDate={disabledDueDate}/>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    )
  }

  const renderPaymentSchedule = () => {
    return (
      <>
        { screens.md ? (
          <Row gutter={[15,15]} className="b-border mt-20 pb-10 c-text-gray fs-12 fw-700 pl-10">
            <Col xs={2}>
              PAYMENT
            </Col>
            <Col xs={7} className="pl-10">
              AMOUNT
            </Col>
            <Col xs={13} className="pl-10">
              DUE
            </Col>
          </Row>
        ) : (
          <Row gutter={[15,15]} className="b-border mt-20 pb-10 c-text-gray fs-12 fw-700 pl-10">
            <Col xs={24}>
              PAYMENT / AMOUNT / DUE
            </Col>
          </Row>
        )}
        { paymentSchedulePayments.map((x,i) => renderPaymentScheduleRow(x,i))}
        { renderFinalPaymentScheduleRow() }
        { paymentSchedulePayments.length < 3 && (
          <div className="blue-link mt-15" onClick={() => onAddPaymentSchedule()}>+ Add payment</div>
        )}
      </>
    )
  }

  const renderSummaryHeader = () => {
    if (screens.md) {
      return (
        <Row align="middle" className="pv-15 t-border b-border ph-15 c-text-gray bg-gray">
          <Col xs={10}>
            <div className="fs-12 fw-700">ITEM</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">QTY</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">PRICE</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">AMOUNT</div>
          </Col>
        </Row> 
      )
    } else {
      return (
        <Row align="middle" className="pv-15 t-border b-border bg-gray ph-15">
          <Col xs={12}>
            <div className="fs-12 fw-700">ITEM</div>
            <div className="fs-12 fw-700">QTY / PRICE</div>
          </Col>
          <Col xs={12}>
            <div className="fs-12 fw-700 text-right">AMOUNT</div>
          </Col>
        </Row> 
      )
    }
  }

  const renderCommonLineItem = (item, index) => {
    if (screens.md) {
      return (
        <Row className="pv-30 ph-15 border mt-10" key={index}>
          <Col xs={10}>
            <div className="fw-700">{item.name}</div>
            { item.discount && (
              <div className="fs-12 c-blue ml-10">{ getDiscountText(item) }</div>
            )}
          </Col>
          <Col xs={4}>
            <div className="text-right">{item.quantity}</div>
          </Col>
          <Col xs={4}>
            <div className="text-right">{formatCurrencyString(item.item_price, currency.code)}</div>
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">{ formatCurrencyString(item.total_price, currency.code) }</div>
            { item.discount && (
              <div className="fs-12 c-blue text-right">- { formatCurrencyString(item.total_discount, currency.code) }</div>
            )}
          </Col>
          <Col xs={2} style={{ alignSelf: 'center'}}>
            <div className="display-flex" style={{ justifyContent: 'flex-end'}} onClick={() => removeLineItem(item.id)}>
              <MdOutlineClose size={20} color={"#CCC"}/>
            </div>
          </Col>
        </Row> 
      )
    } else {
      return (
        <Row className="pv-20 ph-15 border mt-10" key={index}>
          <Col xs={15}>
            <div className="fw-700">{item.name}</div>
            <div className="">{item.quantity} x {formatCurrencyString(item.item_price, currency.code)}</div>
            { item.discount && (
              <div className="fs-12 c-blue ml-10">{ getDiscountText(item) }</div>
            )}
          </Col>
          <Col xs={6}>
            <div className="fs-14 fw-500 text-right">{ formatCurrencyString(item.total_price, currency.code) }</div>
            { item.discount && (
              <div className="fs-12 c-blue text-right">- { formatCurrencyString(item.total_discount, currency.code) }</div>
            )}
          </Col>
          <Col xs={3} style={{ alignSelf: 'center'}}>
            <div className="display-flex" style={{ justifyContent: 'flex-end'}} onClick={() => removeLineItem(item.id)}>
              <MdOutlineClose size={20} color={"#CCC"}/>
            </div>
          </Col>
        </Row> 
      )
    }
  }

  const renderAvailableLineItem = (item, index) => {
    const lineItemIds = lineItems.map(x => x.id)
    const selected = lineItemIds.includes(item.id)
    return (
      <div className="border p-15 mb-10" key={index}>
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-600">{item.name}</div>
            <div>{item.quantity} x {formatCurrencyString(item.item_price, currency.code)}</div>
          </Col>
          <Col>
            { selected ? (
              <div className="display-flex"><FaCheckCircle style={{ fontSize: 20, color: '#536DFE' }}/></div>
            ) : (
              <div className="blue-link" onClick={() => addLineItem(item)}>Add</div>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const renderAvailableLineItems = () => {
    const availablePackages = availableLineItems.filter(x => x.type == "PACKAGE")
    const availableAddOns = availableLineItems.filter(x => x.type == "ADD_ON")

    if (lineItems.length == 0 && availableLineItems.length == 0) {
      return (
        <div className="bg-gray ph-15 pv-30 text-center">There are no packages or add-ons for this event. Navigate to the event details page to add services.</div>
      )
    }

    return (
      <div>
        { availablePackages.length > 0 && (
          <>
            <div className="fw-600 fs-12 mb-5 ml-5 mt-10 c-text-gray">PACKAGES</div>
            { availablePackages.map((x,i) => renderAvailableLineItem(x,i))}
          </>
        )}
        { availableAddOns.length > 0 && (
          <>
            <div className="fw-600 fs-12 mb-5 ml-5 mt-10 c-text-gray">ADD-ONS</div>
            { availableAddOns.map((x,i) => renderAvailableLineItem(x,i))}
          </>
        )}
      </div>
    )
  }

  const renderAddItemModal = () => {
    return (
      <Modal visible={isAddItemModalVisible} footer={null} onCancel={() => setAddItemModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add Item</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddItemModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-15">
            { renderAvailableLineItems() }
          </div>
          <div className="text-right">
            <Button className="admin-small-button" onClick={() => setAddItemModalVisible(false)}>Done</Button>
          </div>
        </div>
      </Modal>
    )
  }

  const renderBillToModal = () => {
    var clientData = clients.map((client) => {
      return {
        value: client.user_id,
        text: client.first_name + " " + client.last_name
      }
    })
    clientData.push({ value: 1, text: "Other" })
    return (
      <Modal visible={isBillToModalVisible} footer={null} onCancel={() => setBillToModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Edit Bill To</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setBillToModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={billToForm} layout="vertical" name="client" onFinish={saveBillToUser}>
            <div className="">
              <div className="pt-10 pb-15">
                <Row align="middle" gutter={[15,15]}>
                  <Col xs={24}>
                    { renderSearchSelectField("Bill To", "bill_to", "Select a person...", clientData, true, (value) => setTempSelectedBillTo(value))}
                  </Col>
                  { tempSelectedBillTo == 1 && (
                    <>
                      <Col xs={24} sm={12}>
                        { renderInputField("First Name", "first_name", true)}
                      </Col>
                      <Col xs={24} sm={12}>
                        { renderInputField("Last Name", "last_name", true)}
                      </Col>
                      <Col xs={24}>
                        { renderEmailField("Email", "email", true)}
                      </Col>
                    </>
                  )}
                </Row>
              </div>
              <div className="text-right">
                <Button className="admin-small-button secondary mr-10" onClick={() => setBillToModalVisible(false)}>Cancel</Button>
                <Button className="admin-small-button" htmlType="submit">Save</Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderDateValidModal = () => {
    return (
      <Modal visible={isDateValidModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setDateValidModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Due Dates</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">The due dates in the payment schedule must be in the correct order.</div>
          <button className="primary-button" type="button" onClick={() => setDateValidModalVisible(false)}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderAmountsValidModal = () => {
    return (
      <Modal visible={isAmountsValidModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setAmountsValidModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Payment Amounts</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">The total amount of payments must not be greater than the amount due.</div>
          <button className="primary-button" type="button" onClick={() => setAmountsValidModalVisible(false)}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderZeroAmountModal = () => {
    return (
      <Modal visible={isZeroAmountModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setZeroAmountModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Invalid Amounts</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">All amounts in the payment schedule must be greater than {formatCurrencyString(0, currency.code)}.</div>
          <button className="primary-button" type="button" onClick={() => setZeroAmountModalVisible(false)}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    const hasTax = getTotalTaxes(lineItems) > 0
    if (hasPayments) {
      return (
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={850}>
          <div className="shadow-card-square mb-20 p-20 text-center">
            <div className="fw-700">EDIT INVOICE</div>
            <div className="mt-5 c-text-gray bg-gray p-15">This invoice can no longer be edited since payments have been recorded.</div>
          </div>
        </FloatingContainer>
      )
    }

    const paddingClass = screens.md ? "p-30" : "p-20"
    return (
      <>
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={850}>
          <div className="shadow-card-square mb-20 p-20 text-center">
            <div className="fw-700">EDIT INVOICE</div>
            <div className="mt-5 c-text-gray bg-gray p-15">Make any necessary adjustments to the invoice below. <br/> Once you are finished, you can move on to the next step.</div>
          </div>
          <div className={`shadow-card-square mt-15`}>
            {/* <div className="bg-light-blue fs-12 fw-600 text-center p-10">EDITING</div> */}
            <div className={`${paddingClass}`}>
              <Row className="" gutter={[30,30]}>
                <Col xs={24} md={24}>
                  <div className="fs-20 fw-700 mb-10">Bill To</div>
                  { renderBillToUser() }
                </Col>
              </Row>
            </div>
          </div>

          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            <div className="fs-20 fw-700 mb-10">Items</div>
            { renderSummaryHeader() }
            { lineItems.length == 0 ? (
              <div className="border ph-15 pv-30 text-center mt-15">
               <div>No line items have been added yet.</div>
                <div className="blue-link" onClick={() => setAddItemModalVisible(true)}>Add item</div>
              </div>
            ) : (
              <div>
                { lineItems.map((x,i) => (renderCommonLineItem(x, i))) }
                <Row align="middle" className="pv-10 ph-15">
                  <Col xs={24}>
                    <div className="blue-link" onClick={() => setAddItemModalVisible(true)}>+ Add item</div>
                  </Col>
                </Row> 
                { hasTax && (
                  <>
                    <Row align="middle" className="pb-20 ph-15">
                      <Col xs={17} md={20}>
                        <div className="fw-700 text-right">Subtotal:</div>
                      </Col>
                      <Col xs={7} md={4}>
                        <div className="text-right">{formatCurrencyString(getSubTotal(lineItems), currency.code)}</div>
                      </Col>
                    </Row>
                    <Row align="middle" className="pb-20 ph-15">
                      <Col xs={17} md={20}>
                        <div className="fw-700 text-right">Tax:</div>
                      </Col>
                      <Col xs={7} md={4}>
                        <div className="text-right">{formatCurrencyString(getTotalTaxes(lineItems), currency.code)}</div>
                      </Col>
                    </Row>
                  </>
                )} 
                <Row align="middle" className="pb-20 ph-15">
                  <Col xs={17} md={20}>
                    <div className="fw-700 text-right">Amount Due:</div>
                  </Col>
                  <Col xs={7} md={4}>
                    <div className="fw-700 text-right">{formatCurrencyString(getAmountDue(lineItems), currency.code)}</div>
                  </Col>
                </Row> 
              </div>
            )}

            <Row className="mt-30" gutter={[20,20]}>
              <Col xs={24} md={24}>
                <div className="fs-14 fw-700 mb-5">Notes</div>
                <Input.TextArea rows={4} placeholder={"Add optional notes, terms, etc."} value={notes} onChange={(e) => setNotes(e.target.value)}/>
              </Col>
            </Row>
          </div>

          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            <div className="fs-20 fw-700">Tips</div>
            <div className="c-text-gray mt-5 mb-15">Give your clients an option to leave you a tip.</div>
            <Switch checked={isTipsActive} onChange={(value) => setTipsActive(value)} /> <span className="ml-10 fw-600">{isTipsActive ? "On" : "Off"}</span>

            <div className="fs-20 fw-700 mt-30 pt-30 t-border">Payment Reminders</div>
            <div className="c-text-gray mt-5 mb-15">Send automatic reminders to your clients when each payment is due.</div>
            <Switch checked={isPaymentRemindersActive} onChange={(value) => onPaymentReminderToggle(value)} /> <span className="ml-10 fw-600">{isPaymentRemindersActive ? "On" : "Off"}</span>
            { isPaymentRemindersActive && (
                <Row align="middle" className="mt-15" gutter={[15,15]}>
                <Col flex={0}>
                  <Checkbox checked={is7DaysBeforeReminderActive} onChange={(e) => set7DaysBeforeReminderActive(e.target.checked)}>7 days before</Checkbox>
                </Col>
                <Col flex={0}>
                  <Checkbox checked={is3DaysBeforeReminderActive} onChange={(e) => set3DaysBeforeReminderActive(e.target.checked)}>3 days before</Checkbox>
                </Col>
                <Col flex={0}>
                  <Checkbox checked={isDueDateReminderActive} onChange={(e) => setDueDateReminderActive(e.target.checked)}>On due date</Checkbox>
                </Col>
                <Col flex={0}>
                  <Checkbox checked={is3DaysAfterReminderActive} onChange={(e) => set3DaysAfterReminderActive(e.target.checked)}>3 days after</Checkbox>
                </Col>
                <Col flex={0}>
                  <Checkbox checked={is7DaysAfterReminderActive} onChange={(e) => set7DaysAfterReminderActive(e.target.checked)}>7 days after</Checkbox>
                </Col>
                <Col flex={0}>
                  <Checkbox checked={is14DaysAfterReminderActive} onChange={(e) => set14DaysAfterReminderActive(e.target.checked)}>14 days after</Checkbox>
                </Col>
              </Row> 
            )}
          </div>

          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            <Row className="" gutter={[20,15]}>
              <Col xs={24} md={24}>
                <div className="fs-20 fw-700 mb-5">Payment Schedule</div>
                <div className="mb-5 c-text-gray">Use a payment schedule to collect deposits/retainers or setup installments.</div>
              </Col>
              <Col xs={24} md={24}>
                <Switch checked={isPaymentScheduleActive} onChange={(value) => onPaymentScheduleToggle(value)} /> <span className="ml-10 fw-600">{isPaymentScheduleActive ? "On" : "Off"}</span>
              </Col>
              { isPaymentScheduleActive && (
                <Col xs={24} md={24}>
                  { renderPaymentSchedule() }
                </Col>
              )}
            </Row>

            { !isPaymentScheduleActive && (
              <>
                <div className="fs-20 fw-700 mt-30 pt-30 t-border">Due Date</div>
                <div className="c-text-gray mt-5 mb-15">The date the final payment is due.</div>

                <Row align="middle">
                  <Col flex={0}>
                    <div className="border mt-10">
                      <DatePicker size="large" format={getDatePickerFormatMedium(accountSettings)} value={dueDate} onChange={(date) => onDueDateChange(date)} allowClear={false} bordered={false} disabledDate={disabledDueDate}/>
                      <Select size="small" value={dueDateOption} style={{ width: 220, textAlign: "right", paddingRight: 10, color: "#777777" }} onChange={(value) => onFinalDueDateOptionChange(value)} bordered={false}>
                        { renderDateOptions() }
                      </Select>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
      { renderPayNowErrorModal() }
      { renderAddItemModal() }
      { renderBillToModal() }
      { renderDateValidModal() }
      { renderAmountsValidModal() }
      { renderZeroAmountModal() }
    </div>
  );
}

export default EventInvoicePage;

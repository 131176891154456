import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row, Col, Modal, Dropdown, Menu, Input, Grid, notification, Form } from 'antd';
import { cloneDeep, isArray, toLower, isEmpty } from 'lodash';
import qs from "qs";
import moment from 'moment';
import { getInvoices, updateInvoiceStatus, getEvents, createInvoice, deleteInvoice, getInvoiceTemplates } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import StatusTag from '../../../components/statusTag';
import FloatingContainer from '../../../components/floatingContainer'
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { EditOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { formatCurrencyString } from '../../../helpers/contractHelper';
import DateFormatTypes from '../../../constants/dateFormatTypes';
import { MdOutlineMoreHoriz, MdOutlineClose, MdOutlineUndo, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FiExternalLink, FiSend, FiCheck, FiTrash, FiLink, FiDollarSign } from "react-icons/fi";
import emptyStateImage from '../../../images/empty-state-icon.png';
import emptySearchImage from '../../../images/empty-search-icon.png';
import FadeWhileInView from '../../../components/fadeWhileInView'
import { formatEventDateShort } from "../../../helpers/dateHelper";
import InvoiceStatus from "../../../constants/invoiceStatus";
import { BsArrowRight } from "react-icons/bs";
import { 
  renderInputField,
  renderSearchSelectField,
  renderEmailField
} from '../../../components/formFields'

const BASE_URL = process.env.REACT_APP_BASE_URL;

const InvoicesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [invoiceTemplates, setInvoiceTemplates] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [isConfirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [totals, setTotals] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [events, setEvents] = useState([]);
  const [eventClients, setEventClients] = useState([]);
  const [isNewInvoiceModalVisible, setNewInvoiceModalVisible] = useState(false);
  const [selectedBillTo, setSelectedBillTo] = useState("");
  const [selectedInvoiceEvent, setSelectedInvoiceEvent] = useState("");
  const [invoiceError, setInvoiceError] = useState("");

  useDocumentTitle("Invoices")
  const [sendRequest] = useApi()
  const navigate = useNavigate();
  const location = useLocation()
  const [currency] = useCurrency()
  const [accountSettings] = useAccountSettings()

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [newInvoiceForm] = Form.useForm();

  var primaryTabs = [
    { name: "All Invoices", key: "all" },
    { name: "Draft", key: "draft" },
    { name: "Outstanding", key: "outstanding" },
    { name: "Paid", key: "paid" },
    { name: "Past Due", key: "past-due" },
    { name: "Cancelled", key: "cancelled" }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    // Apply filter param
    var appliedFilter = "all"
    if (queryStrings.filter) {
      appliedFilter = queryStrings.filter;
    }
    // Apply search param
    var appliedSearch = null;
    if (queryStrings.q) {
      appliedSearch = queryStrings.q
    }
    setSelectedFilter(appliedFilter)
    setSearchText(appliedSearch)
    updateUrl(appliedFilter, appliedSearch)
  }, []);

  const refreshPage = async () => {
    try {
      const invoiceResults = await sendRequest(getInvoices("all"));
      setInvoices(invoiceResults.invoices)
      setTotals(invoiceResults.totals)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const navigateToPDF = (item) => {
    window.open(`${BASE_URL}/pdfs/v2/invoice/${item.invoice_id}`, "_blank")
  }

  const copyLink = () => {
    notification.success({
      message: 'Success!',
      description: 'The link has been copied to your clipboard.',
      duration: 3
    });
  }
  
  const menuItem = (text, icon, action = () => {}) => {
    return (
      <Menu.Item className="ph-15 pv-10">
        <div onClick={action}>
          {icon}<span style={{ marginLeft: 12}}>{text}</span>
        </div>
      </Menu.Item>
    )
  }

  const menu = (item) => {
    const invoiceLink = `${BASE_URL}/v/invoice/${item.invoice_id}`
    if (item.status == InvoiceStatus.DRAFT) {
      return (
        <Menu>
          { menuItem("Edit Invoice", <EditOutlined/>, () => handleEditInvoice(item))}
          { menuItem("Preview Invoice", <EyeOutlined/>, () => handlePreviewInvoice(item))}
          { menuItem("Send Invoice", <FiSend/>, () => handleSendInvoice(item))}
          { menuItem("Mark as Sent", <FiCheck/>, () => handleUpdateInvoiceStatus(item.invoice_id, InvoiceStatus.SENT))}
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToPDF(item))}
          { menuItem("Delete Invoice", <FiTrash/>, () => handleDeleteInvoice(item))}
        </Menu>
      )
    } else if (item.status == InvoiceStatus.SENT) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => handleViewInvoice(item))}
          { menuItem("Edit Invoice", <EditOutlined/>, () => handleEditInvoice(item))}
          { menuItem("Re-Send Invoice", <FiSend/>, () => handleSendInvoice(item))}
          { menuItem("Revert to Draft", <MdOutlineUndo/>, () => handleUpdateInvoiceStatus(item.invoice_id, InvoiceStatus.DRAFT))}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToPDF(item))}
          { menuItem("Cancel Invoice", <FiTrash/>, () => handleCancelInvoice(item))}
        </Menu>
      )
    } else if (item.status == InvoiceStatus.PARTIALLY_PAID) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => handleViewInvoice(item))}
          { menuItem("Re-Send Invoice", <FiSend/>, () => handleSendInvoice(item))}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToPDF(item))}
          { menuItem("Cancel Invoice", <FiTrash/>, () => handleCancelInvoice(item))}
        </Menu>
      )
    } else if (item.status == InvoiceStatus.PAID) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => handleViewInvoice(item))}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToPDF(item))}
        </Menu>
      )
    }
    return (
      <Menu>
        { menuItem("View Invoice", <EyeOutlined/>, () => handleViewInvoice(item))}
      </Menu>
    )
  };

  const getFilteredItems = () => {
    var allInvoices = cloneDeep(invoices);
    var filteredInvoices = allInvoices;

    if (selectedFilter == "draft") {
      filteredInvoices = filteredInvoices.filter(x => x.status == InvoiceStatus.DRAFT)
    } else if (selectedFilter == "paid") {
      filteredInvoices = filteredInvoices.filter(x => x.status == InvoiceStatus.PAID)
    } else if (selectedFilter == "cancelled") {
      filteredInvoices = filteredInvoices.filter(x => x.status == InvoiceStatus.CANCELLED)
    } else if (selectedFilter == "past-due") {
      filteredInvoices = filteredInvoices.filter(x => x.past_due)
    } else if (selectedFilter == "outstanding") {
      filteredInvoices = filteredInvoices.filter(x => x.outstanding)
    }

    if (searchText) {
      const lowerSearchText = toLower(searchText)

      filteredInvoices = filteredInvoices.filter(x => {
        // Check event date
        const eventDate = moment(x.event_date, "YYYY-MM-DD")
        var eventDateShort = eventDate.format("MM/DD/YYYY")
        var eventDateShort2 = eventDate.format("M/D/YYYY")
        var eventDateMedium = eventDate.format("MMM D, YYYY")
        var eventDateLong = eventDate.format("MMMM D, YYYY")
        if (accountSettings.date_format == DateFormatTypes.DMY) {
          eventDateShort = eventDate.format("DD/MM/YYYY")
          eventDateShort2 = eventDate.format("D/M/YYYY")
          eventDateMedium = eventDate.format("D MMM, YYYY")
          eventDateLong = eventDate.format("D MMMM, YYYY")
        }

        const matchesEventDateShort = toLower(eventDateShort).includes(lowerSearchText)
        const matchesEventDateShort2 = toLower(eventDateShort2).includes(lowerSearchText)
        const matchesEventDateMedium = toLower(eventDateMedium).includes(lowerSearchText)
        const matchesEventDateLong = toLower(eventDateLong).includes(lowerSearchText)
        const matchesEventName = toLower(x.event_name).includes(lowerSearchText)
        const matchBillToFirstName = toLower(x.bill_to_first_name).includes(lowerSearchText)
        const matchBillToLastName = toLower(x.bill_to_last_name).includes(lowerSearchText)
        const matchBillToFullName = toLower(x.bill_to_first_name + " " + x.bill_to_last_name).includes(lowerSearchText)
        const matchInvoiceNumber = toLower(x.invoice_number).includes(lowerSearchText)

        return matchesEventDateShort || matchesEventDateShort2 || matchesEventDateMedium || matchesEventDateLong || matchesEventName || matchBillToFirstName || matchBillToLastName || matchBillToFullName || matchInvoiceNumber
      })
    }

    return filteredInvoices;
  }

  const onSearchTextChange = (text) => {
    setSearchText(text)
    updateUrl(selectedFilter, text)
  }

  const onFilterChange = (value) => {
    setSelectedFilter(value)
    updateUrl(value, searchText)
  }

  const updateUrl = (filter, search) => {
    var url = "/admin/invoices"
    var params = []
    if (filter) {
      params.push(`filter=${filter}`)
    }
    if (search) {
      params.push(`q=${search}`)
    }
    if (params.length > 0) {
      url += `?${params.join("&")}`
    }
    navigate(url, { replace: true })
  }
  
  const onNewClick = async () => {
    try {
      setInvoiceError("")
      // Get events if not populated yet
      if (events.length == 0) {
        const eventResults = await sendRequest(getEvents("upcoming"));
        setEvents(eventResults);
      }
      // Get invoice templates if note populated yet
      if (invoiceTemplates.length == 0) {
        const results = await sendRequest(getInvoiceTemplates());
        setInvoiceTemplates(results)
      }

      // Reset all fiels
      newInvoiceForm.resetFields()
      setNewInvoiceModalVisible(true)
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const onNewInvoiceSubmit = async (values) => {
    try {
      const userId = values["bill_to_user_id"]
      const body = {
        ...values,
        bill_to_user_id: userId == 1 ? null : userId,
        event_id: selectedInvoiceEvent
      }
      const results = await sendRequest(createInvoice(body))
      if (results.status == "no-services") {
        setInvoiceError("No services have been added to this event. Please add packages and/or add-ons before continuing.")
      } else if (results.status == "no-line-items") {
        setInvoiceError("All packages and add-ons for this event have already been included in previous invoices.")
      } else if (results.invoice_id) {
        navigate(`/admin/invoices/${results.invoice_id}/edit?from=invoices`)
      } else {
        notification.error({
          message: 'Error!',
          description: 'Something went wrong.',
          duration: 3
        });
      }
    } catch {
      setNewInvoiceModalVisible(false)
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const onBillToChange = (value) => {
    setSelectedBillTo(value)
    const fields = {
      bill_to_first_name: null,
      bill_to_last_name: null,
      bill_to_email: null
    }
    newInvoiceForm.setFieldsValue(fields)
  }

  const onEventChange = (value) => {
    setSelectedInvoiceEvent(value)
    setSelectedBillTo("")

    // Reset bill to fields
    const fields = {
      bill_to_user_id: null,
      bill_to_first_name: null,
      bill_to_last_name: null,
      bill_to_email: null
    }
    newInvoiceForm.setFieldsValue(fields)

    // Find event
    const foundEvent = events.find(x => x.event_id == value)
    if (isArray(foundEvent.clients)) {
      // Populate clients box
      var clientData = foundEvent.clients.map((client) => {
        return {
          value: client.user_id,
          text: `${client.first_name} ${client.last_name}`
        }
      })
      clientData.push({ value: 1, text: "Other" })
      setEventClients(clientData)
    } else {
      setEventClients([{ value: 1, text: "Other" }])
    }
  }

  const handleEditInvoice = (invoice) => {
    navigate(`/admin/invoices/${invoice.invoice_id}/edit?from=invoices`)
  };

  const handlePreviewInvoice = (invoice) => {
    navigate(`/admin/invoices/${invoice.invoice_id}/preview?from=invoices`)
  };

  const handleSendInvoice = (invoice) => {
    navigate(`/admin/invoices/${invoice.invoice_id}/send`)
  };

  const handleViewInvoice = (invoice) => {
    navigate(`/admin/invoices/${invoice.invoice_id}`)
  };

  const handleGoToEvent = (invoice) => {
    navigate(`/admin/events/${invoice.event_id}`)
  };

  const handleDeleteInvoice = (invoice) => {
    setSelectedInvoice(invoice)
    setConfirmRemoveVisible(true)
  };

  const handleCancelInvoice = (invoice) => {
    setSelectedInvoice(invoice)
    setConfirmCancelVisible(true)
  };

  const handleUpdateInvoiceStatus = async (id, status) => {
    try {
      await sendRequest(updateInvoiceStatus(id, { status: status }))
      await refreshPage()
      notification.success({
        message: 'Success!',
        description: 'The invoice status has been updated successfully.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const confirmDeleteInvoice = async () => {
    try {
      await sendRequest(deleteInvoice(selectedInvoice.invoice_id))
      await refreshPage()
      setConfirmRemoveVisible(false)
      notification.success({
        message: 'Success!',
        description: 'Your invoice has been deleted.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
      setConfirmRemoveVisible(false)
    }
  }

  const confirmCancelInvoice = async () => {
    try {
      await handleUpdateInvoiceStatus(selectedInvoice.invoice_id, InvoiceStatus.CANCELLED)
    } finally {
      setConfirmCancelVisible(false)
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Invoices</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/invoices-payments")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
        <Col>
          { invoices.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Invoice</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderInvoice = (invoice, index) => {
    return (
      <div className="shadow-card-square mt-10" key={index}>
        <div className="b-border ph-20 pv-15">
          <Row align="middle" gutter={[15,15]} wrap={false}>
            { screens.sm && (
              <Col flex={0} onClick={() => handleViewInvoice(invoice)}>
                <div className="card-icon"><FiDollarSign/></div>
              </Col>
            )}
            <Col flex={1} onClick={() => handleViewInvoice(invoice)}>
              <div className="fw-700">Invoice #{invoice.invoice_number}</div>
              <div className="c-text-gray">{ formatCurrencyString(invoice.total, currency.code) }</div>
            </Col>
            <Col flex={0}>
              <StatusTag status={invoice.status} size="small"/>
            </Col>
            <Col flex={0}>
              <div className="display-flex" style={{ justifyContent: 'flex-end'}}>
                <Dropdown overlay={menu(invoice)} placement="bottomRight" trigger="click">
                  <div className="dots-container">
                    <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                  </div>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
        <div className="p-20">
          <Row gutter={[15,15]}>
            <Col xs={24} sm={12}>
              <div className="fs-10 c-text-gray">EVENT</div>
              <div className="blue-link" onClick={() => handleGoToEvent(invoice)}>{ invoice.event_name }</div>
              <div className="fs-10 c-text-gray mt-10">EVENT DATE</div>
              <div>{ formatEventDateShort(invoice.event_date, accountSettings) }</div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="fs-10 c-text-gray">BILL TO</div>
              <div>{ invoice.bill_to_first_name } { invoice.bill_to_last_name }</div>
              <div className="fs-10 c-text-gray mt-10">PAYMENTS</div>
              <div>{ formatCurrencyString(invoice.payment_total, currency.code) }</div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderTab = (text, value) => {
    const isSelected = selectedFilter == value
    const selectedStyle = isSelected ? { borderBottom: "2px solid #536DFE", color: "#536DFE", userSelect: 'none' } : { userSelect: 'none' }
    return (
      <div className={`ph-10 pv-10 mt-15 mr-20 fw-600`} style={selectedStyle} onClick={() => onFilterChange(value)}>
        { text }
      </div>
    )
  }

  const mobileTabMenu = () => {
    return (
      <Menu>
        { primaryTabs.map((x,i) => (
          <Menu.Item key={i}>
            <div onClick={() => onFilterChange(x.key)}>
              { x.name }
            </div>
          </Menu.Item>
        ))}
      </Menu>
    )
  };

  const renderInvoiceBox = () => {
    const tabRecord = primaryTabs.find(x => x.key == selectedFilter)
    const tabName = !isEmpty(tabRecord) ? tabRecord.name : ""
    return (
      <>
        <div className="shadow-card-square">
          { screens.lg ? (
            <div className="display-flex ph-20 b-border">
              { renderTab("All Invoices", "all") }
              { renderTab("Draft", "draft") }
              { renderTab("Outstanding", "outstanding") }
              { renderTab("Paid", "paid") }
              { renderTab("Past Due", "past-due") }
              { renderTab("Cancelled", "cancelled") }
            </div>
          ) : (
            <div className="ph-20 pt-10">
              <Dropdown overlay={mobileTabMenu()} placement="bottomRight" trigger="click">
                <div className="tab-dropdown mt-10">
                  <div className="tab-dropdown--text">{tabName}</div>
                  <div className="tab-dropdown--arrow"><MdOutlineKeyboardArrowDown/></div>
                </div>
              </Dropdown>
            </div>
          )}
          <div className="p-15 b-border display-flex flex-row flex-middle">
            <div className="flex-1">
              <Input className="list-search-input-square" size="large" placeholder="Invoice #, client, event date, etc." value={searchText} onChange={(e) => onSearchTextChange(e.target.value)} allowClear={true} prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} />
            </div>
          </div>
        </div>
        { renderInvoiceResults() }
      </>
    )
  }

  const renderInvoices = () => {
    if (invoices.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No invoices yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Add an invoice to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>New Invoice</button>
        </div>
      )
    } else {
      return renderInvoiceBox()
    }
  }

  const renderInvoiceResults = () => {
    if (getFilteredItems().length > 0) {
      return getFilteredItems().map((invoice,i) => renderInvoice(invoice,i));
    } else {
      return (
        <div className="shadow-card mt-10">
          <FadeWhileInView duration={0.5} className="ph-20 pv-40 text-center">
            <div>
              <img src={emptySearchImage} width={200}/>
            </div>
            <div className="fs-18 fw-700 mt-30">
              No invoices found
            </div>
            <div className="fs-14 fw-500 c-text-gray">
              Adjust your filter and try again!
            </div>
          </FadeWhileInView>
        </div>
      )
    }
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Delete Invoice</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to delete <span className="fw-700">Invoice #{selectedInvoice.invoice_number}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeleteInvoice()}>Delete Invoice</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmCancelModal = () => {
    return (
      <Modal visible={isConfirmCancelVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmCancelVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cancel Invoice</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to cancel <span className="fw-700">Invoice #{selectedInvoice.invoice_number}</span>? Clients will no longer be able to make payments towards this invoice.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmCancelInvoice()}>Cancel Invoice</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmCancelVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderNewInvoiceModal = () => {
    var eventData = events.map((event) => {
      return {
        value: event.event_id,
        text: `${event.event_name} (${formatEventDateShort(event.event_date, accountSettings)})`
      }
    })
    var templateData = invoiceTemplates.map((template) => {
      return {
        value: template.invoice_template_id,
        text: template.invoice_template_name
      }
    })
    var clientData = []
    clientData.push({ value: 1, text: "Other" })
    return (
      <Modal visible={isNewInvoiceModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Invoice</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setNewInvoiceModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={newInvoiceForm} layout="vertical" name="new-invoice" onFinish={onNewInvoiceSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderSearchSelectField("Invoice Template (optional)", "invoice_template_id", "Select a template...", templateData, false) }
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Event", "event_id", "Select an event...", eventData, true, onEventChange) }
            </Col>
            { selectedInvoiceEvent && (
              <Col xs={24}>
                { renderSearchSelectField("Bill To", "bill_to_user_id", "Select client...", eventClients, true, onBillToChange) }
              </Col>
            )}
            { selectedBillTo == 1 && (
              <>
              <Col xs={24} md={12}>
                {renderInputField("First Name", "bill_to_first_name", true)}
              </Col>
              <Col xs={24} md={12}>
                {renderInputField("Last Name", "bill_to_last_name", true)}
              </Col>
              <Col xs={24}>
                {renderEmailField("Email", "bill_to_email", true)}
              </Col>
              </>
            )}
            { invoiceError && (
              <Col xs={24}>
                <div className="c-red text-center">{ invoiceError }</div>
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setNewInvoiceModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <div className="mb-15">
          <Row gutter={[15,15]}>
            <Col xs={24} sm={8}>
              <div className="shadow-card-square pv-20 text-center">
                <div className="c-text-gray fw-700">Total Outstanding</div>
                <div className="c-text-gray fs-24">{ formatCurrencyString(totals.outstanding, currency.code) }</div>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="shadow-card-square pv-20 text-center">
                <div className="c-text-gray fw-700">Past Due</div>
                <div className="c-text-gray fs-24">{ formatCurrencyString(totals.pastDue, currency.code) }</div>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="shadow-card-square pv-20 text-center">
                <div className="c-text-gray fw-700">Total Paid</div>
                <div className="c-text-gray fs-24">{ formatCurrencyString(totals.paid, currency.code) }</div>
              </div>
            </Col>
          </Row>
        </div>
        { renderInvoices() }
        { renderConfirmRemoveModal() }
        { renderConfirmCancelModal() }
        { renderNewInvoiceModal() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default InvoicesPage;
